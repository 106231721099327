<template>
    <div>
        <v-form ref="form">
            <div class="mx-auto w-full max-w-960 mt-8 mb-8 p-4 bg-white-pure l-box-shadow rounded">
                <div class="mb-8">
                    <BackLink :backRoute="userListRoute" :linkText="goBackText" />
                </div>

                <h4 class="font-display font-semibold text-xl mb-4">Staff Details</h4>
                <UserSummary v-model="user" />

                <UserForm ref="userForm" v-model="user" :readOnly="readOnly"/>

                <Permissions
                    ref="permissions"
                    class="w-full mt-5 mb-5"
                    v-model="userLocationPrograms"
                    :readOnly="readOnly"
                />

                <div class="mt-8 flex flex-row justify-center">
                    <router-link
                        class="mr-4 px-6 v-btn v-btn--depressed theme--light v-size--default cursor-pointer router-link-active bg-grey-4"
                        :to="userListRoute"
                        :class="{'pointer-events-none': saving || performingAction}"
                    >
                        <span class="text-black">Cancel</span>
                    </router-link>

                    <v-btn
                        depressed
                        color="primary"
                        class="mr-4 v-btn--flat inline"
                        :class="{'pointer-events-none': saving || performingAction}"
                        v-if="isValidAction(user.status, UserAction.Reactivate)"
                        @click.prevent="performAction(UserAction.Reactivate)"
                    >
                        <fa-icon :icon="getIcon('Reactivate', 'check')" :spin="isCurrentAction('Reactivate')" class="mr-1" />
                        {{ getActionButtonText('Reactivate') }}
                    </v-btn>
                    <v-btn
                        depressed
                        class="mr-4 v-btn--flat inline text-orange bg-orange-light"
                        :class="{'pointer-events-none': saving || performingAction}"
                        v-if="isValidAction(user.status, UserAction.Deactivate)"
                        @click.prevent="performAction(UserAction.Deactivate)"
                    >
                        <fa-icon :icon="getIcon('Deactivate', 'times')" :spin="isCurrentAction('Deactivate')" class="mr-1" />
                        {{ getActionButtonText('Deactivate') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        class="mr-4 v-btn--flat inline"
                        :class="{'pointer-events-none': saving || performingAction}"
                        v-if="isValidAction(user.status, UserAction.ReInvite)"
                        @click.prevent="performAction(UserAction.ReInvite)"
                    >
                        <fa-icon :icon="getIcon('Re-Invite', 'envelope')" :spin="isCurrentAction('Re-Invite')" class="mr-1" />
                        {{ getActionButtonText('Re-Invite') }}
                    </v-btn>
                    <v-btn
                        depressed
                        class="mr-4 v-btn--flat inline text-orange bg-orange-light"
                        :class="{'pointer-events-none': saving || performingAction}"
                        v-if="isValidAction(user.status, UserAction.Unlock)"
                        @click.prevent="performAction(UserAction.Unlock)"
                    >
                        <fa-icon :icon="getIcon('Unlock', 'times')" :spin="isCurrentAction('Unlock')" class="mr-1" />
                        {{ getActionButtonText('Unlock') }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        class="mr-4 v-btn--flat inline hidden"
                        :class="{'pointer-events-none': saving || performingAction}"
                        v-if="isValidAction(user.status, UserAction.ChangePassword)"
                        @click.prevent="performAction(UserAction.ChangePassword)"
                    >
                        <fa-icon :icon="getIcon('Change Password', 'redo')" :spin="isCurrentAction('Change Password')" class="mr-1" />
                        {{ getActionButtonText('Change Password') }}
                    </v-btn>
                    <v-btn
                        depressed
                        class="v-btn--flat inline w-30"
                        type="success"
                        color="success"
                        :class="{'pointer-events-none': saving || performingAction}"
                        :disabled="readOnly"
                        @click.prevent="submitHandler"
                    >
                        <fa-icon v-if="saving" icon="spinner" spin class="mr-2 text-primary-light" />
                        {{saveButtonText}}
                    </v-btn>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import { onMounted, toRefs, reactive } from '@vue/composition-api'
import DEACTIVATE_USER from '@/graphql/mutations/deactivateUser.gql'
import REACTIVATE_USER from '@/graphql/mutations/reactivateUser.gql'
import REINVIET_USER from '@/graphql/mutations/reinviteUser.gql'
import UPDATE_USER from '@/graphql/mutations/updateUser.gql'
import snakecaseKeys from 'snakecase-keys'
import BackLink from '@/components/shared/BackLink'
import UserSummary from '@/components/user-management/partials/UserSummary'
import UserForm from '@/components/user-management/partials/UserForm'
import Permissions from '@/components/user-management/partials/Permissions'
import { UserHelper, UserStatus, UserAction } from '@/components/shared/mixins/userMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { userService } from '@/services/user.service'

const DEFAULT_USER = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    phone: ''
}

export default {
    name: 'UserDetails',
    components: { BackLink, UserSummary, UserForm, Permissions },
    mixins: [UserHelper, MessageDialog],
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'user-list') {
                vm.userListRoute = from;
            }
        });
    },
    mounted() {
        this.$root.$on('change-user-status', this.changeUserStatus)
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate() && this.$refs.userForm.validate()
            return this.isValid
        },
        submitHandler() {
            if (!this.validate()) return

            this.saveUser()
        },
        saveUser() {
            this.saving = true
            const locationProgramsToAdd = this.userLocationPrograms
                .filter(
                    ({ locationProgramId }) =>
                        !this.user.locationPrograms.some(
                            a => a.locationProgramId === locationProgramId
                        )
                )
                .map(({ locationProgramId }) => ({
                    locationProgramId,
                    userId: this.user.userId
                }))

            const locationProgramsToDelete = this.user.locationPrograms
                .filter(
                    ({ locationProgramId }) =>
                        !this.userLocationPrograms.some(
                            a => a.locationProgramId === locationProgramId
                        )
                )
                .map(({ locationProgramId }) => locationProgramId)

            let user = (({ role, firstName, lastName, phone, email }) => ({
                role,
                firstName,
                lastName,
                name: `${firstName} ${lastName}`,
                phone,
                email
            }))(this.user)
            user = snakecaseKeys(user, { deep: true })

            const variables = {
                userId: this.user.userId,
                user: user,
                auth0User: user,
                locationProgramsToAdd: snakecaseKeys(locationProgramsToAdd, { deep: true }),
                locationProgramsToDelete
            }

            this.$apollo
                .mutate({
                    mutation: UPDATE_USER,
                    variables: variables
                })
                .then(async (res) => {
                    await this.$apollo.queries.user.refetch()
                    await this.$apollo.provider.defaultClient.resetStore()
                    this.saving = false
                    this.message = 'The user profile has been updated.'
                    this.type = 'success'
                })
                .catch(err => {
                    let message = err?.graphQLErrors[0]?.message
                    if (!message) message = 'Error updating the user profile'
                    this.message = message
                    this.type = 'error'
                })
                .finally(() => {
                    this.saving = false
                    this.showMessage({ duration: 5000 })
                })
        },
        isValidAction(status, action) {
            return this.getValidUserActions(status).includes(action)
        },
        performAction(action) {
            switch (action) {
                case UserAction.Reactivate:
                    this.activateUser(this.user.userId, action)
                    break
                case UserAction.Deactivate:
                    this.deactivateUser(this.user.userId, action)
                    break
                case UserAction.ReInvite:
                    this.reinviteUser(this.user.userId, action)
                    break
                default:
                    console.log(action)
            }
        },
        changeUserStatus({variables, action}) {
            this.currentAction = action.toLowerCase()
            this.performingAction = true
            let mutation = null

            switch(action) {
                case UserAction.Reactivate:
                    mutation = REACTIVATE_USER
                    break
                case UserAction.Deactivate:
                    mutation = DEACTIVATE_USER
                    break
                case UserAction.ReInvite:
                    mutation = REINVIET_USER
                    break
                default:
                    mutation = null
                    console.log(action)
            }

            if (mutation) {
                this.$apollo
                    .mutate({
                        mutation,
                        variables
                    }).then(async res => {
                        await this.$apollo.provider.defaultClient.resetStore()

                        this.message = 'The student status has been updated.'
                        this.type = 'success'
                    }).catch(err => {
                        this.message = 'Error updating the student status'
                        this.type = 'error'
                    }).finally(() => {
                        this.showMessage({duration: 5000})
                        this.performingAction = false
                        this.currentAction = ''
                    })
            }
        },
        getActionButtonText(btnText) {
            return this.performingAction ? 'please wait...' : btnText
        },
        getIcon(btnText, defaultIcon) {
            return this.isCurrentAction(btnText) ? 'spinner' : defaultIcon
        },
        isCurrentAction(action) {
            return this.currentAction.toLowerCase() === action.toLowerCase()
        }
    },
    computed: {
        saveButtonText() {
            return this.saving ? 'Saving...' : 'Save'
        },
        readOnly() {
            return this.user.status === UserStatus.Deactivated
        }
    },
    destroyed() {
        this.$root.$off('change-user-status')
    },
    setup(_, { root }) {
        const route = root.$route
        const state = reactive({
            userId: route.params.userId,
            isValid: true,
            users: [],
            goBackText: `Back to Staff Management`,
            userListRoute: { name: 'user-list' },
            loading: true,
            user: { ...DEFAULT_USER },
            userLocationPrograms: [],
            saving: false,
            performingAction: false,
            currentAction: ''
        })

        onMounted(async () => {
            try {
                const user = await userService.getUser(state.userId)

                user.locationPrograms =
                    user.locationPrograms?.map(
                        ({ locationProgram: { locationProgramId, locationId, programId } }) => ({
                            locationProgramId,
                            locationId,
                            programId
                        })
                    ) ?? []
                state.user = user

                state.userLocationPrograms = state.user.locationPrograms.map(locationProgram => ({
                    ...locationProgram
                }))
            } catch (err) {
                console.log('error', err)
            } finally {
                state.loading = false
            }
        })

        return {
            ...toRefs(state)
        }
    }
}
</script>
